import iconav from '../resources/svgs/iconav.svg';
import '../styles/new-site.css';

function NewSite() {
  return (
    <div className="layout">
      <img src={iconav} className="icon-anvic" alt="logo" />
      
      <h2 className="title">New site<br/> coming soon.</h2>
      <div className="subtitle">
        <p className="sub">Meanwhile, you can reach me at:</p>
        <p className="links">
          <a href="https://www.instagram.com/iamanvic" className="link" target="_blank" rel="noopener noreferrer">Instagram</a> e&nbsp;
          <a href="https://www.linkedin.com/in/iamanvic" className="link" target="_blank" rel="noopener noreferrer">Linkedin</a>.
        </p>
      </div>
    </div>
  );
}

export default NewSite;
